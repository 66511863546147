<template>
  <b-modal
    id="edit-options-modal"
    size="lg"
    title="Edit Item"
    cancel-title="Cancel"
    ok-title="Add Items"
    :no-close-on-backdrop="true"
    @ok="handleOk"
    @show="handleShow"
    @hidden="resetModal"
  >
    <b-overlay :show="show" large rounded="lg" spinner-variant="primary">
      <validation-observer ref="accountRules" tag="form">
        <b-row>
          <b-col md="12">
            <h4 style="padding-bottom: 20px">Item Details</h4>
          </b-col>
          <b-col md="12">
            <validation-provider #default="{ errors }" name="item" rules="required">
              <b-form-group>
                <label for="InputHelp">Item Name</label>
                <b-input-group>
                  <b-form-input
                    v-model="item"
                    type="text"
                    placeholder="Ex. Access Control"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <label for="InputHelp">Description for Clients</label>
              <small class="text-muted"> optional</small>
              <b-form-textarea
                id="details"
                v-model="clientDescription"
                placeholder="Ex. HID-compatible system, 2x suite entry doors"
                rows="2"
                :state="clientDescription.length <= maxChar"
                :class="clientDescription.length > maxChar ? 'text-danger' : ''"
              />
              <small
                class="textarea-counter-value float-right"
                :class="clientDescription.length > maxChar ? 'bg-danger' : ''"
              >
                <span class="char-count">{{ clientDescription.length }}</span> /
                {{ maxChar }}
              </small>
              <small class="text-muted">Appears on client facing quotes.</small>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <validation-provider #default="{ errors }" name="Internal Note">
              <b-form-group>
                <label for="InputHelp">Internal Note</label>
                <small class="text-muted"> optional</small>
                <b-form-textarea
                  id="internalNote"
                  v-model="internalNote"
                  placeholder="Internal notes and reason for changes."
                  rows="2"
                  :state="internalNote.length <= maxCharInternal"
                  :class="internalNote.length > maxCharInternal ? 'text-danger' : ''"
                />
                <small
                  class="textarea-counter-value float-right"
                  :class="internalNote.length > maxCharInternal ? 'bg-danger' : ''"
                >
                  <span class="char-count">{{ internalNote.length }}</span> /
                  {{ maxCharInternal }}
                </small>
                <template v-if="errors[0]">
                  <small class="text-danger">{{ errors[0] }}</small
                  ><br />
                </template>
                <small class="text-muted"
                  >This field is required when making an adjustment to base spec.</small
                >
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="12" style="padding-top: 0px">
            <hr style="border: 1px solid rgba(31, 41, 47, 0.05)" />
          </b-col>
          <b-col md="12">
            <h4 style="padding-bottom: 20px">Pricing</h4>
          </b-col>
          <b-col md="12">
            <validation-provider
              #default="{ errors }"
              name="budget group"
              rules="required"
            >
              <b-form-group>
                <label for="InputHelp">Budget Group</label>
                <v-select
                  v-model="budgetGroup"
                  label="title"
                  :options="option"
                  @input="toggleChanged()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="12">
            <validation-provider
              #default="{ errors }"
              name="Tender Code"
              :rules="{ required: true }"
            >
              <b-form-group>
                <label for="InputHelp">Tender Code</label>
                <v-select
                  v-model="tenderCode"
                  label="code_name"
                  :options="
                    budgetGroup == 'Construction'
                      ? uniformat.Construction
                      : budgetGroup == 'FF&E'
                      ? uniformat['FF&E']
                      : budgetGroup == 'AV/IT'
                      ? uniformat.AVIT
                      : uniformat['Soft Costs']
                  "
                  item-text="code_name"
                  required
                  :reduce="(tenderCode) => tenderCode.code"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <label for="InputHelp">Quantity</label>
              <b-form-input v-model="qty" type="number" placeholder="0.00" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <validation-provider #default="{ errors }" name="price">
              <b-form-group>
                <label for="InputHelp">Unit Price</label>
                <b-input-group prepend="$">
                  <b-form-input v-model="unitPrice" type="number" placeholder="0.00" />
                </b-input-group>
                <small class="labelpersqft"
                  >Total Price
                  {{
                    budgetGroup !== "Construction" && budgetGroup !== "Soft Costs"
                      ? "(w/ D&I)"
                      : ""
                  }}:
                  {{
                    "$" +
                    Number(
                      parseFloat(unitPrice * qty).toFixed(2)
                    ).toLocaleString(undefined, { minimumFractionDigits: 2 })
                  }}
                </small>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider #default="{ errors }" name="cost">
              <b-form-group>
                <label for="InputHelp">COGS / Unit</label>
                <b-input-group prepend="$">
                  <b-form-input v-model="unitCost" type="number" placeholder="0.00" />
                </b-input-group>
                <small class="labelpersqft"
                  >Total COGS:
                  {{
                    "$" +
                    Number(
                      parseFloat(unitCost * qty).toFixed(2)
                    ).toLocaleString(undefined, { minimumFractionDigits: 2 })
                  }}</small
                ><br />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </validation-observer>
      <template #overlay>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle" />
          <strong> Loading...</strong>
        </div>
      </template>
    </b-overlay>
    <template #modal-footer="{ ok, cancel }">
      <!-- Emulate built in modal footer ok and cancel button actions -->
      <b-col style="padding-left: 0px">
        <b-button variant="danger" @click="deleteOption()">
          <feather-icon icon="Trash2Icon" />
          Delete
        </b-button>
      </b-col>
      <b-col style="text-align: end; padding-right: 0px">
        <!-- Button with custom close trigger value -->
        <b-button
          variant="outline-secondary"
          style="margin-right: 10px"
          @click="cancel()"
        >
          Cancel
        </b-button>

        <b-button variant="primary" @click="ok()"> Save Option </b-button>
      </b-col>
    </template>
  </b-modal>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormFile,
  BCardText,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BTable,
  BOverlay,
  BButton,
  BFormTextarea,
  BSpinner,
  BFormCheckbox,
  BFormRadioGroup,
} from "bootstrap-vue";
import { required } from "@validations";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import tenderCodes from "@/assets/objects/tender-codes.json";
import uniformat from "@/assets/objects/uniformat-codes.json";
import { UilArrowUp, UilArrowDown } from "@iconscout/vue-unicons";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BCardText,
    BTable,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BButton,
    vSelect,
    BFormInvalidFeedback,
    LottieAnimation,
    BOverlay,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormTextarea,
    BSpinner,
    BFormCheckbox,
    BFormRadioGroup,
    UilArrowUp,
    UilArrowDown,
  },
  props: ["changeOrderId", "sqft", "opportunityId", "itemToEdit"],
  data() {
    return {
      userName: localStorage.getItem("name"),
      show: false,
      item: "",
      details: "",
      clientDescription: "",
      price: 0,
      cost: 0,
      unitCost: 0,
      unitPrice: 0,
      qty: 0,
      fields: {
        details: "",
      },
      budget_group: "",
      tenderCode: "",
      constructionItem: "",
      selectedConstructionItem: [],
      required,
      option: ["FF&E", "AV/IT", "Construction", "Soft Costs"],
      maxChar: 500,
      maxCharInternal: 500,
      isBaseSpec: false,
      budgetGroup: "",
      id: 0,
      tenderCodes: tenderCodes.v2.filter((c) => c.cost_code != "0901"),
      uniformat: uniformat,
      selectedRadio: "new",
      optionsRadio: [
        { text: "New Item", value: "new" },
        { text: "Base Spec Adjustment", value: "adjustment" },
      ],
      isAdjustment: false,
      constructionWorkId: null,
    };
  },

  methods: {
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.validationForm();
    },
    handleShow() {
      this.item = this.itemToEdit.name;
      this.clientDescription = this.itemToEdit.client_description
        ? this.itemToEdit.client_description
        : "";
      this.unitPrice = this.itemToEdit.unit_price;
      this.price = this.itemToEdit.total_price;
      this.cost = this.itemToEdit.total_cost;
      this.id = this.itemToEdit.id;
      this.budgetGroup = this.itemToEdit.budget_group;
      this.tenderCode = this.itemToEdit.uniformat;
      this.qty = this.itemToEdit.quantity;
      this.unitCost = this.itemToEdit.unit_cost;
      this.internalNote = this.itemToEdit.internal_note
        ? this.itemToEdit.internal_note
        : "";
    },
    resetModal() {
      this.name = "";
      this.clientDescription = "";
      this.price = 0;
      this.cost = 0;
      this.budgetGroup = "";

      this.tenderCode = "";
      this.internalNote = "";
      this.isAdjustment = false;
      this.qty = 0;
      this.unitCost = 0;
      this.unitPrice = 0;
      this.selectedConstructionItem = [];

      this.constructionItem = "";
      this.constructionWorkId = null;
      this.selectedRadio = "new";
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true);
            this.show = true;
            this.fields = {
              name: this.item,
              client_description: this.clientDescription,
              sqft: this.sqft,
              change_order_id: this.changeOrderId,
              total_price: this.unitPrice * this.qty,
              total_cost: this.unitCost * this.qty,
              unit_cost: this.unitCost,
              unit_price: this.unitPrice,
              budget_group: this.budgetGroup,
              uniformat: this.tenderCode,
              quantity: this.qty,
              internal_note: this.internalNote,
            };
            this.$http
              .put(
                `/opportunities/${this.opportunityId}/change-orders/${this.changeOrderId}/change-orders-lines/${this.id}`,
                this.fields
              )
              .then((response) => {
                this.$emit("updatedLines", response.data);
                this.show = false;
                this.$bvModal.hide("edit-options-modal");
              })
              .catch((error) => {
                this.show = false;
                this.showToast("danger", error.response.data.message);
              });
          } else {
            reject();
          }
        });
      });
    },
    deleteOption() {
      this.show = true;
      this.$http
        .delete(
          `/opportunities/${this.opportunityId}/change-orders/${this.changeOrderId}/change-orders-lines/${this.id}`
        )
        .then((response) => {
          this.show = false;
          this.$emit("updatedLines", response.data);
          this.showToast(
            "Success",
            "This item has been successfully deleted.",
            "Item Deleted"
          );
          this.$bvModal.hide("edit-options-modal");
        })
        .catch((error) => {
          this.show = false;
          this.showToast("danger", "Error Deleting Item", "Notification");
          this.$bvModal.hide("edit-options-modal");
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style lang="scss" scoped>
.increase {
  color:  rgba(59, 171, 255, 1) ;
  font-size: 12px;
}

.decrease {
  color: #ea5455;
  font-size: 12px;
}
</style>
