<template>
  <b-card class="invoice-preview-card" style="margin: auto">
    <!-- <div v-if="changeOrder.opportunity.geography=='Montréal'">
      <div class="d-flex justify-content-between align-items-center; mt-2">
        <b-col cols="12">
          <h3 class="font-weight-bold">Change Order Quote Display</h3>
          <hr />
        </b-col>
      </div>
      <div
        class="d-flex justify-content-between align-items-center cell-styling-double"
        style="height: 65px"
      >
        <b-col
          align-self="start"
          class="d-flex align-items-center cell-styling first-column label-exclusions"
          cols="9"
        >
          <div>
            <p
              style="
                font-weight: 500;
                font-size: 15px;
                line-height: 24px;
                color: #6e6b7b;
                margin-bottom: 0px;
              "
            >
              Language
            </p>
            <small>Changes the language of the Change Order Quote PDF package.</small>
          </div>
        </b-col>
        <b-col
          align-self="end"
          class="align-items-center justify-content-start label-exclusions"
          cols="3"
        >
          <v-select
            :clearable="false"
            v-model="changeOrder.language"
            :options="languageItems"
            item-value="value"
            label="label"
            item-text="label"
            :reduce="(item) => item.value"
            @input="toggleChanged()"
          ></v-select>
        </b-col>
      </div>
    </div> -->
    <div class="d-flex justify-content-between align-items-center mt-2">
      <!-- admin title -->
      <b-col cols="12" class="my-2">
        <h3 class="font-weight-bold">Admin fee</h3>
        <hr />
      </b-col>
    </div>
    <!-- start admin inputs -->
    <b-row class="mr-1">
      <b-col align-self="start" cols="7">
        <h5 class="ml-1">Include Admin Fee</h5>
      </b-col>
      <b-col align-self="end" cols="4">
        <b-input-group append="%">
          <b-form-input
            id="example-input"
            v-model="admin_fee"
            autocomplete="off"
            @change="AdminFeeChanged"
            placeholder="%"
            show-decade-nav
            :disabled="!admin_fee_enabled"
            type="number"
          />
        </b-input-group>
        <small style="color: #b9b9c3">As per CCDC</small>
      </b-col>
      <b-col cols="1">
        <div class="mx-3">
          <b-form-checkbox
            @change="AdminFeeChanged"
            style="margin-top: 10px"
            name="check-button"
            switch
            v-model="admin_fee_enabled"
          />
        </div>
      </b-col>
    </b-row>
    <!-- end admin inputs -->
  </b-card>
</template>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<script>
import {
  BCard,
  BCardBody,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BTooltip,
  BFormInput,
  BFormDatepicker,
  BInputGroupAppend,
  BInputGroup,
  BRow,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { UilInfoCircle } from "@iconscout/vue-unicons";

export default {
  name: "ReviewConstructionSpecSection",
  data() {
    return {
      settings: {},
      admin_fee_enabled: this.changeOrder.admin_fee_enabled,
      admin_fee: this.changeOrder.admin_fee,
      items: [
        {
          label: "Not Included",
          value: false,
        },
        {
          label: "Included",
          value: true,
        },
      ],
      languageItems: [
        {
          label: "English",
          value: "en",
        },
        {
          label: "Français",
          value: "fr",
        },
      ],
    };
  },
  components: {
    BCard,
    BCardBody,
    BCol,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    vSelect,
    UilInfoCircle,
    BTooltip,
    BFormInput,
    BFormDatepicker,
    BInputGroupAppend,
    BInputGroup,
    BRow,
  },
  props: ["changeOrder"],
  methods: {
    toggleChanged() {
      this.settings.assumptions_exclusions = this.changeOrder.assumptions_exclusions;
      this.settings.ffe_toggle = this.changeOrder.ffe_toggle;
      this.settings.avit_toggle = this.changeOrder.avit_toggle;
      this.settings.language = this.changeOrder.language;
      this.$emit("optionsChanged", this.settings);
    },
    AdminFeeChanged() {
      let adminFee;
      adminFee = {
        adminFee: this.admin_fee,
        adminFeeEnabled: this.admin_fee_enabled,
      };
      this.$emit("AdminFeeChanged", adminFee);
    },
  },
};
</script>

<style scoped>
.label-exclusions {
  margin: auto;
}

.first-row-border {
  border-top: 1px solid #ebe9f1;
  border-bottom: 1px solid #ebe9f1;
}

.following-row-border {
  border-bottom: 1px solid #ebe9f1;
}

.cell-styling {
  height: 33px;
}

.cell-styling-double {
  height: 65px;
}
</style>
