var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"options-modal","size":"lg","title":"Add New Item","cancel-title":"Cancel","ok-title":"Add Items","cancel-variant":"outline-secondary","no-close-on-backdrop":true,"ok-disabled":_vm.isBusy,"cancel-disabled":_vm.isBusy},on:{"ok":_vm.handleOk,"show":_vm.resetModal,"hidden":_vm.resetModal}},[_c('b-overlay',{attrs:{"show":_vm.show,"large":"","rounded":"lg","spinner-variant":"primary"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',[_vm._v(" Loading...")])],1)]},proxy:true}])},[_c('validation-observer',{ref:"accountRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('h4',{staticStyle:{"padding-bottom":"20px"}},[_vm._v("Item Details")])]),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"item","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"for":"InputHelp"}},[_vm._v("Item Name")]),_c('b-input-group',[_c('b-form-input',{attrs:{"type":"text","placeholder":"Ex. Access Control"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"InputHelp"}},[_vm._v("Description for Clients")]),_c('small',{staticClass:"text-muted"},[_vm._v(" optional")]),_c('b-form-textarea',{class:_vm.clientDescription.length > _vm.maxChar ? 'text-danger' : '',attrs:{"id":"clientDescription","placeholder":"Ex. HID-compatible system, 2x suite entry doors","rows":"2","state":_vm.clientDescription.length <= _vm.maxChar},model:{value:(_vm.clientDescription),callback:function ($$v) {_vm.clientDescription=$$v},expression:"clientDescription"}}),_c('small',{staticClass:"textarea-counter-value float-right",class:_vm.clientDescription.length > _vm.maxChar ? 'bg-danger' : ''},[_c('span',{staticClass:"char-count"},[_vm._v(_vm._s(_vm.clientDescription.length))]),_vm._v(" / "+_vm._s(_vm.maxChar)+" ")]),_c('small',{staticClass:"text-muted"},[_vm._v("Appears on client facing quotes.")])],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Internal Note"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"for":"InputHelp"}},[_vm._v("Internal Note")]),_c('small',{staticClass:"text-muted"},[_vm._v(" optional")]),_c('b-form-textarea',{class:_vm.internalNote.length > _vm.maxCharInternal ? 'text-danger' : '',attrs:{"id":"internalNote","placeholder":"Internal notes and reason for changes.","rows":"2","state":_vm.internalNote.length <= _vm.maxCharInternal},model:{value:(_vm.internalNote),callback:function ($$v) {_vm.internalNote=$$v},expression:"internalNote"}}),_c('small',{staticClass:"textarea-counter-value float-right",class:_vm.internalNote.length > _vm.maxCharInternal ? 'bg-danger' : ''},[_c('span',{staticClass:"char-count"},[_vm._v(_vm._s(_vm.internalNote.length))]),_vm._v(" / "+_vm._s(_vm.maxCharInternal)+" ")]),(errors[0])?[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('br')]:_vm._e(),_c('small',{staticClass:"text-muted"},[_vm._v("Hidden from clients.")])],2)]}}])})],1),_c('b-col',{staticStyle:{"padding-top":"0px"},attrs:{"md":"12"}},[_c('hr',{staticStyle:{"border":"1px solid rgba(31, 41, 47, 0.05)"}})]),_c('b-col',{attrs:{"md":"12"}},[_c('h4',{staticStyle:{"padding-bottom":"20px"}},[_vm._v("Pricing")])]),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"budget group","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"for":"InputHelp"}},[_vm._v("Budget Group")]),_c('v-select',{attrs:{"label":"title","options":_vm.option},on:{"input":function($event){return _vm.toggleChanged()}},model:{value:(_vm.budgetGroup),callback:function ($$v) {_vm.budgetGroup=$$v},expression:"budgetGroup"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Tender Code","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"for":"InputHelp"}},[_vm._v("Tender Code")]),_c('v-select',{attrs:{"label":"code_name","options":_vm.budgetGroup == 'Construction'
                    ? _vm.uniformat.Construction
                    : _vm.budgetGroup == 'FF&E'
                    ? _vm.uniformat['FF&E']
                    : _vm.budgetGroup == 'AV/IT'
                    ? _vm.uniformat.AVIT
                    : _vm.uniformat['Soft Costs'],"item-text":"code_name","required":"","reduce":function (tenderCode) { return tenderCode.code; }},model:{value:(_vm.tenderCode),callback:function ($$v) {_vm.tenderCode=$$v},expression:"tenderCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"InputHelp"}},[_vm._v("Quantity")]),_c('b-form-input',{attrs:{"type":"number","placeholder":"0.00"},model:{value:(_vm.qty),callback:function ($$v) {_vm.qty=$$v},expression:"qty"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"price"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"for":"InputHelp"}},[_vm._v("Unit Price")]),_c('b-input-group',{attrs:{"prepend":"$"}},[_c('b-form-input',{attrs:{"type":"number","placeholder":"0.00"},model:{value:(_vm.unitPrice),callback:function ($$v) {_vm.unitPrice=$$v},expression:"unitPrice"}})],1),_c('small',{staticClass:"labelpersqft"},[_vm._v("Total Price"+_vm._s(_vm.budgetGroup !== "Construction" && _vm.budgetGroup !== "Soft Costs" ? "(w/ D&I)" : "")+": "+_vm._s("$" + Number( parseFloat(_vm.unitPrice * _vm.qty).toFixed(2) ).toLocaleString(undefined, { minimumFractionDigits: 2 }))+" ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"cost"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"for":"InputHelp"}},[_vm._v("COGS / Unit")]),_c('b-input-group',{attrs:{"prepend":"$"}},[_c('b-form-input',{attrs:{"type":"number","placeholder":"0.00"},model:{value:(_vm.unitCost),callback:function ($$v) {_vm.unitCost=$$v},expression:"unitCost"}})],1),_c('small',{staticClass:"labelpersqft"},[_vm._v("Total COGS: "+_vm._s("$" + Number( parseFloat(_vm.unitCost * _vm.qty).toFixed(2) ).toLocaleString(undefined, { minimumFractionDigits: 2 })))]),_c('br'),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }